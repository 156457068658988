"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.creditVisionAutoReasons = void 0;
exports.creditVisionAutoReasons = {
    D00: 'There are no accounts verified in last 12 months. Optimum value is having an account but none 60+ days past due.',
    D01: 'There are one or more accounts 60+ days past due, verified in 12 months, opened in 24 months, with a balance > $0. Optimum value is no accounts 60+ days past due.',
    D10: 'There is 1 account ever 90 or more days past due, or in a worse status. Optimum value is no accounts ever 90 or more days past due, or in a worse status.',
    D11: 'There are 2 accounts ever 90 or more days past due, or in a worse status. Optimum value is no accounts ever 90 or more days past due, or in a worse status.',
    D12: 'There are 3 accounts ever 90 or more days past due, or in a worse status. Optimum value is no accounts ever 90 or more days past due, or in a worse status.',
    D13: 'There are 4 accounts ever 90 or more days past due, or in a worse status. Optimum value is no accounts ever 90 or more days past due, or in a worse status.',
    D14: 'There are 5-10 accounts ever 90 or more days past due, or in a worse status. Optimum value is no accounts ever 90 or more days past due, or in a worse status.',
    D15: 'There are 11-14 accounts ever 90 or more days past due, or in a worse status. Optimum value is no accounts ever 90 or more days past due, or in a worse status.',
    D16: 'There are 15 or more accounts ever 90 or more days past due, or in a worse status. Optimum value is no accounts ever 90 or more days past due, or in a worse status.',
    D20: 'There is 1 third party collection. Optimum value is no collections.',
    D21: 'There are 2 third party collections. Optimum value is no collections.',
    D22: 'There are 3 third party collections. Optimum value is no collections.',
    D23: 'There are 4 third party collections. Optimum value is no collections.',
    D24: 'There are 5 third party collections. Optimum value is no collections.',
    D25: 'There are 6 third party collections. Optimum value is no collections.',
    D26: 'There are 7 third party collections. Optimum value is no collections.',
    D27: 'There are 8 or more third party collections. Optimum value is no collections.',
    D30: 'There are no open accounts with valid balance and credit limit verified in last 12 months. Optimum value is no accounts with utilization > 75% that are 60 or more days past due.',
    D31: 'There is one open account with utilization > 75% and 60 or more days past due. Optimum value is no accounts with utilization > 75% that are 60 or more days past due.',
    D32: 'There are 2 or more open accounts with utilization > 75% and 60 or more days past due. Optimum value is no accounts with utilization > 75% that are 60 or more days past due.',
    D40: 'There is 1 account verified in last 5 years that is 90+ days past due. Optimum value is no accounts 90+ days past due.',
    D41: 'There are 2 accounts verified in last 5 years that are 90+ days past due. Optimum value is no accounts 90+ days past due.',
    D42: 'There are 3 accounts verified in last 5 years that are 90+ days past due. Optimum value is no accounts 90+ days past due.',
    D43: 'There are 4 accounts verified in last 5 years that are 90+ days past due. Optimum value is no accounts 90+ days past due.',
    D44: 'There are 5 accounts verified in last 5 years that are 90+ days past due. Optimum value is no accounts 90+ days past due.',
    D45: 'There are 6-10 accounts verified in last 5 years that are 90+ days past due. Optimum value is no accounts 90+ days past due.',
    D46: 'There are 11-14 accounts verified in last 5 years that are 90+ days past due. Optimum value is no accounts 90+ days past due.',
    D47: 'There are 15 or more accounts verified in last 5 years that are 90+ days past due. Optimum value is no accounts 90+ days past due.',
    D50: 'There is 1 third-party collection in last 5 years. Optimum value is no third party collections in last 5 years.',
    D51: 'There are 2 third-party collections in last 5 years. Optimum value is no third party collections in last 5 years.',
    D52: 'There are 3 third-party collections in last 5 years. Optimum value is no third party collections in last 5 years.',
    D53: 'There are 4 third-party collections in last 5 years. Optimum value is no third party collections in last 5 years.',
    D54: 'There are 5 or more third-party collections in last 5 years. Optimum value is no third party collections in last 5 years.',
    DA0: 'Months since most recent delinquency is 1. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DA1: 'Months since most recent delinquency is 1 and maximum credit card utilization over last 12 months is 0%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DA2: 'Months since most recent delinquency is 1 and maximum credit card utilization over last 12 months is 1%-24%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DA3: 'Months since most recent delinquency is 1 and maximum credit card utilization over last 12 months is 25%-49%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DA4: 'Months since most recent delinquency is 1 and maximum credit card utilization over last 12 months is 50%-74%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DA5: 'Months since most recent delinquency is 1 and maximum credit card utilization over last 12 months is 75% or more. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DB0: 'Months since most recent delinquency is 2-6. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DB1: 'Months since most recent delinquency is 2-6 and maximum credit card utilization over last 12 months is 0%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DB2: 'Months since most recent delinquency is 2-6 and maximum credit card utilization over last 12 months is 1%-24%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DB3: 'Months since most recent delinquency is 2-6 and maximum credit card utilization over last 12 months is 25%-49%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DB4: 'Months since most recent delinquency is 2-6 and maximum credit card utilization over last 12 months is 50%-74%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DB5: 'Months since most recent delinquency is 2-6 and maximum credit card utilization over last 12 months is 75% or more. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DC0: 'Months since most recent delinquency is 7-18. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DC1: 'Months since most recent delinquency is 7-18 and maximum credit card utilization over last 12 months is 0%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DC2: 'Months since most recent delinquency is 7-18 and maximum credit card utilization over last 12 months is 1%-24%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DC3: 'Months since most recent delinquency is 7-18 and maximum credit card utilization over last 12 months is 25%-49%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DC4: 'Months since most recent delinquency is 7-18 and maximum credit card utilization over last 12 months is 50%-74%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DC5: 'Months since most recent delinquency is 7-18 and maximum credit card utilization over last 12 months is 75% or more. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DD0: 'Months since most recent delinquency 19-24. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DD1: 'Months since most recent delinquency is 19-24 and maximum credit card utilization over last 12 months is 0%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DD2: 'Months since most recent delinquency is 19-24 and maximum credit card utilization over last 12 months is 1%-24%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DD3: 'Months since most recent delinquency is 19-24 and maximum credit card utilization over last 12 months is 25%-49%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DD4: 'Months since most recent delinquency is 19-24 and maximum credit card utilization over last 12 months is 50%-74%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DD5: 'Months since most recent delinquency is 19-24 and maximum credit card utilization over last 12 months is 75% or more. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DE0: 'Months since most recent delinquency is 25-48. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DE1: 'Months since most recent delinquency is 25-48 and maximum credit card utilization over last 12 months is 0%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DE2: 'Months since most recent delinquency is 25-48 and maximum credit card utilization over last 12 months is 1%-24%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DE3: 'Months since most recent delinquency is 25-48 and maximum credit card utilization over last 12 months is 25%-49%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DE4: 'Months since most recent delinquency is 25-48 and maximum credit card utilization over last 12 months is 50%-74%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DE5: 'Months since most recent delinquency is 25-48 and maximum credit card utilization over last 12 months is 75% or more. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DF0: 'Months since most recent delinquency is 49 or more. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DF1: 'Months since most recent delinquency is 49 or more and maximum credit card utilization over last 12 months is 0%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DF2: 'Months since most recent delinquency is 49 or more and maximum credit card utilization over last 12 months is 1% to 24%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DF3: 'Months since most recent delinquency is 49 or more and maximum credit card utilization over last 12 months is 25% to 49%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DF4: 'Months since most recent delinquency is 49 or more and maximum credit card utilization over last 12 months is 50% to 74%. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DF5: 'Months since most recent delinquency is 49 or more and maximum credit card utilization over last 12 months is 75% or more. Optimum value is no delinquency and maximum credit card utilization over last 12 months is > 0% and <1%.',
    DG0: 'There are no delinquencies; there are not enough data points to calculate maximum credit card utilization over last 12 months. Optimum value is no delinquency and the maximum credit card utilization over last 12 months is > 0% and <1%.',
    DG1: 'There are no delinquencies and the maximum credit card utilization over last 12 months is 0%. Optimum value is no delinquency and the maximum credit card utilization over last 12 months is > 0% and < 1%.',
    DG2: 'There are no delinquencies and the maximum credit card utilization over last 12 months is 1% to 24%. Optimum value is no delinquency and the maximum credit card utilization over last 12 months is > 0% and <1%.',
    DG3: 'There are no delinquencies and the maximum credit card utilization over last 12 months is 25% to 49%. Optimum value is no delinquency and the maximum credit card utilization over last 12 months is > 0% and <1%.',
    DG4: 'There are no delinquencies and the maximum credit card utilization over last 12 months is 50% to 74%. Optimum value is no delinquency and the maximum credit card utilization over last 12 months is > 0% and <1%.',
    DG5: 'There are no delinquencies and the maximum credit card utilization over last 12 months is 75% or more. Optimum value is no delinquency and the maximum credit card utilization over last 12 months is > 0% and <1%.',
    DH0: 'There are no accounts. Optimum value is no delinquency and the maximum credit card utilization over last 12 months is > 0% and <1%.',
    G00: 'There are no accounts. Optimum value is 2 or more currently satisfactory open accounts verified in last 24 months.',
    G01: 'There are no open accounts verified in last 24 months. Optimum value is 2 or more currently satisfactory open accounts verified in last 24 months.',
    G02: 'There are no currently satisfactory open accounts verified in last 24 months. Optimum value is 2 or more currently satisfactory open accounts verified in last 24 months.',
    G03: 'There is 1 currently satisfactory open account verified in last 24 months. Optimum value is 2 or more currently satisfactory open accounts verified in last 24 months.',
    G04: 'There are 3 or more currently satisfactory open accounts verified in last 24 months. Optimum value is 2 currently satisfactory open accounts verified in last 24 months.',
    G10: 'Months since oldest account opened cannot be calculated because open date is missing. Optimum value is 240 or more months since oldest account opened.',
    G11: 'There are no accounts. Optimum value is 240 or more months since oldest account opened.',
    G12: 'Oldest account opened in current month. Optimum value is 240 or more months since oldest account opened.',
    G13: 'Months since oldest account opened is 1-24. Optimum value is 240 or more months since oldest account opened.',
    G14: 'Months since oldest account opened is 25-60. Optimum value is 240 or more months since oldest account opened.',
    G15: 'Months since oldest account opened is 61-120. Optimum value is 240 or more months since oldest account opened.',
    G16: 'Months since oldest account opened is 121-180. Optimum value is 240 or more months since oldest account opened.',
    G17: 'Months since oldest account opened is 181-239. Optimum value is 240 or more months since oldest account opened.',
    G20: 'There are no auto accounts. Optimum value is 24 or more months since oldest auto account opened.',
    G21: 'Oldest auto account opened in last 11 months. Optimum value is 24 or more months since oldest auto account opened.',
    G22: 'Oldest auto account opened in last 12-23 months. Optimum value is 24 or more months since oldest auto account opened.',
    G23: 'Oldest auto account opened in last 24-47 months. Optimum value is 48 or more months since oldest auto account opened.',
    G30: 'There are no bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G31: 'There is 1 bank revolving account. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G32: 'There are 2 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G33: 'There are 11 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G34: 'There are 12 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G35: 'There are 13 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G36: 'There are 14 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G37: 'There are 15 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G38: 'There are 16 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G39: 'There are 17 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G3A: 'There are 18 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G3B: 'There are 19 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G3C: 'There are 20 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G3D: 'There are 21 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G3E: 'There are 22 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G3F: 'There are 23 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G3G: 'There are 24 bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G3H: 'There are 25 or more bank revolving accounts. Optimum value is no accounts or 3-10 bank revolving accounts.',
    G40: 'Most recent credit card opened in current month. Optimum value is 240 or more months.',
    G41: 'Months since most recent credit card opened is 1-12. Optimum value is 240 or more months.',
    G42: 'Months since most recent credit card opened is 13-24. Optimum value is 240 or more months.',
    G43: 'Months since most recent credit card opened is 25-36. Optimum value is 240 or more months.',
    G44: 'Months since most recent credit card opened is 37-48. Optimum value is 240 or more months.',
    G45: 'Months since most recent credit card opened is 49-60. Optimum value is 240 or more months.',
    G46: 'Months since most recent credit card opened is 61-72. Optimum value is 240 or more months.',
    G47: 'Months since most recent credit card opened is 73-84. Optimum value is 240 or more months.',
    G48: 'Months since most recent credit card opened is 85-96. Optimum value is 240 or more months.',
    G49: 'Months since most recent credit card opened is 97-108. Optimum value is 240 or more months.',
    G4A: 'Months since most recent credit card opened is 109-120. Optimum value is 240 or more months.',
    G4B: 'Months since most recent credit card opened is 121-239. Optimum value is 240 or more months.',
    G4C: 'No credit card accounts. Optimum value is credit card open 240 or more months.',
    G4D: 'No accounts. Optimum value is credit card open 240 or more months.',
    G4E: 'Most recent credit card opened in current month. Optimum value is no credit card accounts.',
    G4F: 'Most recent credit card opened 1-12 months ago+. Optimum value is no credit card accounts.',
    G4G: 'Most recent credit card opened 13-24 months ago. Optimum value is no credit card accounts.',
    G4H: 'Most recent credit card opened 25-36 months ago. Optimum value is no credit card accounts.',
    G4I: 'Most recent credit card opened 37-48 months ago. Optimum value is no credit card accounts.',
    G4J: 'Most recent credit card opened 49-58 months ago. Optimum value is no credit card accounts.',
    G4K: 'Most recent credit card opened 59 months ago or more. Optimum value is no credit card accounts.',
    G4L: 'No accounts. Optimum value is no credit card accounts.',
    G50: 'Open date on mortgage missing. Optimum value is mortgage 0-12 months old.',
    G51: 'No accounts. Optimum value is mortgage 0-12 months old.',
    G52: 'No mortgage accounts. Optimum value is mortgage 0-12 months old.',
    G53: 'Months since oldest mortgage opened is 13-24 months. Optimum value is 0-12 months.',
    G54: 'Months since oldest mortgage opened is 25-36 months. Optimum value is 0-12 months.',
    G55: 'Months since oldest mortgage opened is 37-48 months. Optimum value is 0-12 months.',
    G56: 'Months since oldest mortgage opened is 49 months or more. Optimum value is 0 to 12 months.',
    G60: 'There are no accounts on consumer credit report. Optimum value is 10-15 accounts.',
    G61: 'There are 1-3 accounts on consumer credit report. Optimum value is 10-15 accounts.',
    G62: 'There are 4-6 accounts on consumer credit report. Optimum value is 10-15 accounts.',
    G63: 'There are 7-9 accounts on consumer credit report. Optimum value is 10-15 accounts.',
    G64: 'There are 16-18 accounts on consumer credit report. Optimum value is 10-15 accounts.',
    G65: 'There are 19-24 accounts on consumer credit report. Optimum value is 10-15 accounts.',
    G66: 'There are more than 24 accounts on consumer credit report. Optimum value is 10-15 accounts.',
    G70: 'Months since oldest account opened excluding auto and mortgage cannot be calculated because open date is missing. Optimum value is 420 months or more.',
    G71: 'There are no accounts. Optimum value is 420 months or more since oldest account opened excluding auto and mortgage.',
    G72: 'There are no accounts other than auto and mortgage accounts. Optimum value is 420 months or more since oldest account opened excluding auto and mortgage.',
    G73: 'Oldest account excluding auto and mortgage accounts opened < 25 months ago. Optimum value is 420 months or more.',
    G74: 'Months since oldest account opened excluding auto and mortgage accounts is 25-60. Optimum value is 420 or more.',
    G75: 'Months since oldest account opened excluding auto and mortgage accounts is 61-120. Optimum value is 420 or more.',
    G76: 'Months since oldest account opened excluding auto and mortgage accounts is 121-180. Optimum value is 420 or more.',
    G77: 'Months since oldest account opened excluding auto and mortgage accounts is 181-240. Optimum value is 420 or more.',
    G78: 'Months since oldest account opened excluding auto and mortgage accounts is 241-300. Optimum value is 420 or more.',
    G79: 'Months since oldest account opened excluding auto and mortgage accounts is 301-360. Optimum value is 420 or more.',
    G7A: 'Months since oldest account opened excluding auto and mortgage accounts is 361-419. Optimum value is 420 or more.',
    G80: 'There are no accounts on the credit report. Optimum value is 3-6 credit cards verified in last 5 years.',
    G81: 'There are no credit cards on the credit report. Optimum value is 3-6 credit cards verified in last 5 years.',
    G82: 'There are no credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G83: 'There is 1 credit card verified in last 5 years. Optimum value is 3-6 credit cards verified in last 5 years.',
    G84: 'There are 2 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G85: 'There are 7 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G86: 'There are 8 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G87: 'There are 9 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G88: 'There are 10 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G89: 'There are 11 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8A: 'There are 12 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8B: 'There are 13 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8C: 'There are 14 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8D: 'There are 15 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8E: 'There are 16 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8F: 'There are 17 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8G: 'There are 18 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8H: 'There are 19 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8I: 'There are 20 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8J: 'There are 21 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8K: 'There are 22 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8L: 'There are 23 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8M: 'There are 24 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8N: 'There are 25 credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in years.',
    G8O: 'There are 26 or more credit cards verified in last 5 years. Optimum value is 3-6 credit cards verified in last 5 years.',
    G92: 'Oldest account opened in current month. Optimum value is 61 months or more since oldest account opened.',
    G93: 'Months since oldest account opened is 1-24. Optimum value is 61 months or more.',
    G94: 'Months since oldest account opened is 25-60. Optimum value is 61 months or more.',
    GA0: 'Age is missing and average number of months accounts on file cannot be calculated because open date missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA1: 'Age is missing and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA2: 'Age is missing and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA3: 'Age is missing and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA4: 'Age is missing and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA5: 'Age is missing and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA6: 'Age is missing and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA7: 'Age is 16-20 and average number of months accounts on file cannot be calculated because open date missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA8: 'Age is 16-20 and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GA9: 'Age is 16-20 and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAA: 'Age is 16-20 and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAB: 'Age is 16-20 and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAC: 'Age is 16-20 and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAD: 'Age is 16-20 and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAE: 'Age is 21-25 and average number of months accounts on file cannot be calculated because open date is missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAF: 'Age is 21-25 and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAG: 'Age is 21-25 and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAH: 'Age is 21-25 and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAI: 'Age is 21-25 and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAJ: 'Age is 21-25 and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAK: 'Age is 21-25 and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAL: 'Age is 26-30 and average number of months accounts on file cannot be calculated because open date is missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAM: 'Age is 26-30 and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAN: 'Age is 26-30 and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAO: 'Age is 26-30 and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAP: 'Age is 26-30 and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAQ: 'Age is 26-30 and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAR: 'Age is 26-30 and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAS: 'Age is 31-40 and average number of months accounts on file cannot be calculated because open date is missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAT: 'Age is 31-40 and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAU: 'Age is 31-40 and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAV: 'Age is 31-40 and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAW: 'Age is 31-40 and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAX: 'Age is 31-40 and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GAY: 'Age is 31-40 and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB0: 'Age is 41-50 and average number of months accounts on file cannot be calculated because open date is missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB1: 'Age is 41-50 and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB2: 'Age is 41-50 and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB3: 'Age is 41-50 and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB4: 'Age is 41-50 and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB5: 'Age is 41-50 and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB6: 'Age is 41-50 and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB7: 'Age is 51-60 and average number of months accounts on file cannot be calculated because open date is missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB8: 'Age is 51-60 and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GB9: 'Age is 51-60 and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBA: 'Age is 51-60 and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBB: 'Age is 51-60 and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBC: 'Age is 51-60 and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBD: 'Age is 51-60 and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBE: 'Age is 61-70 and average number of months accounts on file cannot be calculated because open date is missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBF: 'Age is 61-70 and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBG: 'Age is 61-70 and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBH: 'Age is 61-70 and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBI: 'Age is 61-70 and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBJ: 'Age is 61-70 and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBK: 'Age is 61-70 and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBL: 'Age is 16-20 and average number of months accounts on file cannot be calculated because open date is missing. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBM: 'Age is 71 or more and there are no accounts. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBN: 'Age is 71 or more and average number of months accounts on file is < 30. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBO: 'Age is 71 or more and average number of months accounts on file is 30 to 59. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBP: 'Age is 71 or more and average number of months accounts on file is 60 to 89. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBQ: 'Age is 71 or more and average number of months accounts on file is 90 to 119. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GBR: 'Age is 71 or more and average number of months accounts on file is 120 to 140. Optimum value is age 30 or less and average number of months accounts on file is 141 or more.',
    GC0: 'Age is missing and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 30 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC1: 'Age is missing and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC2: 'Age is missing and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC3: 'Age is missing and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC4: 'Age is missing and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC5: 'Age is missing and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC6: 'Age is missing and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC7: 'Age is missing and average number of months accounts on file (excluding auto and mortgage) is 60-80. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC8: 'Age is 16-20 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GC9: 'Age is 16-20 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCA: 'Age is 16-20 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCB: 'Age is 16-20 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCC: 'Age is 16-20 and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCD: 'Age is 16-20 and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCE: 'Age is 16-20 and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCF: 'Age is 16-20 and average number of months accounts on file (excluding auto and mortgage) is 60-80. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCG: 'Age is 21-25 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCH: 'Age is 21-25 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCI: 'Age is 21-25 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCJ: 'Age is 21-25 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCK: 'Age is 21-25 and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCL: 'Age is 21-25 and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCM: 'Age is 21-25 and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCN: 'Age is 21-25 and average number of months accounts on file (excluding auto and mortgage) is > 59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCO: 'Age is 26-30 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCP: 'Age is 26-30 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCQ: 'Age is 26-30 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCR: 'Age is 26-30 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCS: 'Age is 26-30 and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCT: 'Age is 26-30 and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCU: 'Age is 26-30 and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCV: 'Age is 26-30 and average number of months accounts on file (excluding auto and mortgage) is > 59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCW: 'Age is 31-35 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCX: 'Age is 31-35 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GCY: 'Age is 31-35 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD0: 'Age is 31-35 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD1: 'Age is 31-35 and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD2: 'Age is 31-35 and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD3: 'Age is 31-35 and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD4: 'Age is 31-35 and average number of months accounts on file (excluding auto and mortgage) is > 59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD5: 'Age is 36-40 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD6: 'Age is 36-40 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD7: 'Age is 36-40 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD8: 'Age is 36-40 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GD9: 'Age is 36-40 and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDA: 'Age is 36-40 and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDB: 'Age is 36-40 and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDC: 'Age is 36-40 and average number of months accounts on file (excluding auto and mortgage) is > 59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDD: 'Age is 41-45 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDE: 'Age is 41-45 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDF: 'Age is 41-45 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDG: 'Age is 41-45 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDH: 'Age is 41-45 and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDI: 'Age is 41-45 and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDJ: 'Age is 41-45 and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDK: 'Age is 41-45 and average number of months accounts on file (excluding auto and mortgage) is > 59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDL: 'Age is 46-50 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDM: 'Age is 46-50 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDN: 'Age is 46-50 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDO: 'Age is 46-50 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDP: 'Age is 46-50 and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDQ: 'Age is 46-50 and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDR: 'Age is 46-50 and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDS: 'Age is 46-50 and average number of months accounts on file (excluding auto and mortgage) is > 59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDT: 'Age is 51-55 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDU: 'Age is 51-55 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDV: 'Age is 51-55 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDW: 'Age is 51-55 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDX: 'Age is 51-55 and average number of months accounts on file (excluding auto and mortgage) is 24-35. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GDY: 'Age is 51-55 and average number of months accounts on file (excluding auto and mortgage) is 36-47. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE0: 'Age is 51-55 and average number of months accounts on file (excluding auto and mortgage) is 48-59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE1: 'Age is 51-55 and average number of months accounts on file (excluding auto and mortgage) is > 59. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE2: 'Age is 56-60 and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE3: 'Age is 56-60 and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE4: 'Age is 56-60 and average number of months accounts on file (excluding auto and mortgage) is < 12. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE5: 'Age is 56-60 and average number of months accounts on file (excluding auto and mortgage) is 12-23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE6: 'Age is 56-60 and average number of months accounts on file (excluding auto and mortgage) is > 23. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE7: 'Age is 61 or more and average number of months accounts on file (excluding auto and mortgage) cannot be calculated because open date missing. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE8: 'Age is 61 or more and there are no accounts on file. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GE9: 'Age is 61 or more and average number of months accounts on file (excluding auto and mortgage) is < 3. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GEA: 'Age is 61 or more and average number of months accounts on file (excluding auto and mortgage) is 3-5. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GEB: 'Age is 61 or more and average number of months accounts on file (excluding auto and mortgage) is 6-11. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GEC: 'Age is 61 or more and average number of months accounts on file (excluding auto and mortgage) is 12-14. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GED: 'Age is 61 or more and average number of months accounts on file (excluding auto and mortgage) is 15 or more. Optimum value is age 20 or less and average number of months accounts on file (excluding auto and mortgage) is 81 or more.',
    GF0: 'Age is missing and average number of months accounts on file for accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts for accounts verified in last 5 years is 60 or more.',
    GF1: 'Age is missing and there is no account. Optimum value is age 20 and under and average number of months accounts on file for accounts verified in last 5 years is 60 or more.',
    GF2: 'Age is missing and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts on file for accounts verified in last 5 years is 60 or more.',
    GF3: 'Age is missing and average number of months accounts on file for accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts on file for accounts verified in last 5 years is 60 or more.',
    GF4: 'Age is missing and average number of months accounts on file for accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts on file for accounts verified in last 5 years is 60 or more.',
    GF5: 'Age is missing and average number of months accounts on file for accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts on file for accounts verified in last 5 years is 60 or more.',
    GF6: 'Age is missing and average number of months accounts on file for accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts on file for accounts verified in last 5 years is 60 or more.',
    GF7: 'Age is missing and average number of months accounts on file for accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts on file for accounts verified in last 5 years is 60 or more.',
    GF8: 'Age is 16-20 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GF9: 'Age is 16-20 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFA: 'Age is 16-20 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFB: 'Age is 16-20 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFC: 'Age is 16-20 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFD: 'Age is 16-20 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFE: 'Age is 16-20 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFF: 'Age is 16-20 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFG: 'Age is 21 - 25 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFH: 'Age is 21 - 25 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFI: 'Age is 21 - 25 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFJ: 'Age is 21 - 25 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFK: 'Age is 21 - 25 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFL: 'Age is 21 - 25 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFM: 'Age is 21 - 25 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFN: 'Age is 21 - 25 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFO: 'Age is 21 - 25 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFP: 'Age is 26 - 30 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFQ: 'Age is 26 - 30 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFR: 'Age is 26 - 30 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFS: 'Age is 26 - 30 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFT: 'Age is 26 - 30 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFU: 'Age is 26 - 30 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFV: 'Age is 26 - 30 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFW: 'Age is 26 - 30 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFX: 'Age is 26 - 30 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GFY: 'Age is 31 -35 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG0: 'Age is 31 -35 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG1: 'Age is 31 -35 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG2: 'Age is 31 -35 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG3: 'Age is 31 -35 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG4: 'Age is 31 -35 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG5: 'Age is 31 -35 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG6: 'Age is 31 -35 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG7: 'Age is 31 -35 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG8: 'Age is 36 -40 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GG9: 'Age is 36 -40 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGA: 'Age is 36 -40 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGB: 'Age is 36 -40 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGC: 'Age is 36 -40 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGD: 'Age is 36 -40 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGE: 'Age is 36 -40 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGF: 'Age is 36 -40 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGG: 'Age is 36 -40 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGH: 'Age is 41 -45 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGI: 'Age is 41 -45 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGJ: 'Age is 41 -45 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGK: 'Age is 41 -45 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGL: 'Age is 41 -45 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGM: 'Age is 41 -45 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGN: 'Age is 41 -45 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGO: 'Age is 41 -45 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGP: 'Age is 41 -45 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGQ: 'Age is 46 -50 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGR: 'Age is 46 -50 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGS: 'Age is 46 -50 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGT: 'Age is 46 -50 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGU: 'Age is 46 -50 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGV: 'Age is 46 -50 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGW: 'Age is 46 -50 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGX: 'Age is 46 -50 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GGY: 'Age is 46 -50 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH0: 'Age is 51 -55 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH1: 'Age is 51 -55 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH2: 'Age is 51 -55 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH3: 'Age is 51 -55 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH4: 'Age is 51 -55 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH5: 'Age is 51 -55 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH6: 'Age is 51 -55 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH7: 'Age is 51 -55 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH8: 'Age is 51 -55 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GH9: 'Age is 56 -60 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHA: 'Age is 56 -60 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHB: 'Age is 56 -60 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHC: 'Age is 56 -60 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHD: 'Age is 56 -60 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHE: 'Age is 56 -60 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHF: 'Age is 56 -60 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHG: 'Age is 56 -60 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHH: 'Age is 56 -60 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHI: 'Age is 61 -65 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHJ: 'Age is 61 -65 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHK: 'Age is 61 -65 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHL: 'Age is 61 -65 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHM: 'Age is 61 -65 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHN: 'Age is 61 -65 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHO: 'Age is 61 -65 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHP: 'Age is 61 -65 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHQ: 'Age is 61 -65 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHR: 'Age is 66 -70 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHS: 'Age is 66 -70 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHT: 'Age is 66 -70 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHU: 'Age is 66 -70 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHV: 'Age is 66 -70 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHW: 'Age is 66 -70 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHX: 'Age is 66 -70 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GHY: 'Age is 66 -70 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI0: 'Age is 66 -70 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI1: 'Age is 71 -75 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI2: 'Age is 71 -75 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI3: 'Age is 71 -75 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI4: 'Age is 71 -75 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI5: 'Age is 71 -75 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI6: 'Age is 71 -75 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI7: 'Age is 71 -75 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI8: 'Age is 71 -75 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GI9: 'Age is 71 -75 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIA: 'Age is 76 -80 and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIB: 'Age is 76 -80 and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIC: 'Age is 76 -80 and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GID: 'Age is 76 -80 and average number of months accounts verified in last 5 years is 0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIE: 'Age is 76 -80 and average number of months accounts verified in last 5 years is 12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIF: 'Age is 76 -80 and average number of months accounts verified in last 5 years is 24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIG: 'Age is 76 -80 and average number of months accounts verified in last 5 years is 36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIH: 'Age is 76 -80 and average number of months accounts verified in last 5 years is 48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GII: 'Age is 76 -80 and average number of months accounts verified in last 5 years is 60 or more. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIJ: 'Age is 81 or more and average number of months accounts verified in last 5 years cannot be calculated. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIK: 'Age is 81 or more and there is no account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIL: 'Age is 81 or more and there is no verified account in last 5 years. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIM: 'Age is 81 or more and average number of months accounts verified in last 5 years is  0 - 11. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIN: 'Age is 81 or more and average number of months accounts verified in last 5 years is  12 - 23. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIO: 'Age is 81 or more and average number of months accounts verified in last 5 years is  24 - 35. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIP: 'Age is 81 or more and average number of months accounts verified in last 5 years is  36 - 47. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIQ: 'Age is 81 or more and average number of months accounts verified in last 5 years is  48 - 59. Optimum value is age 20 and under and average number of months accounts verified in last 5 years is 60 or more.',
    GIR: 'Age is 81 or more and average number of months accounts verified in last 5 years is 60 ore more. Optimum value is age 20 and under and average number of months accounts verified in years is 60 or more.',
    GJ0: 'There are no accounts on consumer credit report. Optimum value is 12 accounts.',
    GJ1: 'There are 1-3 accounts on consumer credit report. Optimum value is 12 accounts.',
    GJ2: 'There are 4-6 accounts on consumer credit report. Optimum value is 12 accounts.',
    GJ3: 'There are 7-11 accounts on consumer credit report. Optimum value is 12 accounts.',
    GJ4: 'There are 13-18 accounts on consumer credit report. Optimum value is 12 accounts.',
    GJ5: 'There are 19-24 accounts on consumer credit report. Optimum value is 12 accounts.',
    GJ6: 'There are more than 24 accounts on consumer credit report. Optimum value is 12 accounts.',
    GK0: 'Months since oldest account opened excluding auto and mortgage cannot be calculated because open date is missing. Optimum value is 600 months or more.',
    GK1: 'There are no accounts. Optimum value is 600 months or more since oldest account opened excluding auto and mortgage.',
    GK2: 'There are no accounts other than auto and mortgage accounts. Optimum value is 600 months or more since oldest account opened excluding auto and mortgage.',
    GK3: 'Oldest account excluding auto and mortgage accounts opened < 25 months ago. Optimum value is 600 months or more.',
    GK4: 'Months since oldest account opened excluding auto and mortgage accounts is 25-60. Optimum value is 600 or more.',
    GK5: 'Months since oldest account opened excluding auto and mortgage accounts is 61-120. Optimum value is 600 or more.',
    GK6: 'Months since oldest account opened excluding auto and mortgage accounts is 121-180. Optimum value is 600 or more.',
    GK7: 'Months since oldest account opened excluding auto and mortgage accounts is 181-240. Optimum value is 600 or more.',
    GK8: 'Months since oldest account opened excluding auto and mortgage accounts is 241-300. Optimum value is 600 or more.',
    GK9: 'Months since oldest account opened excluding auto and mortgage accounts is 301-360. Optimum value is 600 or more.',
    GKA: 'Months since oldest account opened excluding auto and mortgage accounts is 361-599. Optimum value is 600 or more.',
    S00: 'There is one inquiry. Optimum value is no inquiries.',
    S01: 'There are 2 inquiries. Optimum value is no inquiries.',
    S02: 'There are 3 inquiries. Optimum value is no inquiries.',
    S03: 'There are 4 inquiries. Optimum value is no inquiries.',
    S04: 'There are 5 inquiries. Optimum value is no inquiries.',
    S05: 'There are 6 inquiries. Optimum value is no inquiries.',
    S06: 'There are 7 inquiries. Optimum value is no inquiries.',
    S07: 'There are 8 inquiries. Optimum value is no inquiries.',
    S08: 'There are 9 inquiries. Optimum value is no inquiries.',
    S09: 'There are 10 or more inquiries. Optimum value is no inquiries.',
    S10: 'There was 1 or more auto inquiries in the last 6 months. Optimum value is no auto inquiries.',
    S30: 'Most recent inquiry was in current month. Optimum value is no inquiries.',
    S31: 'Months since most recent inquiry was 1-6 months ago. Optimum value is no inquiries.',
    S32: 'Months since most recent inquiry was 7-12 months ago. Optimum value is no inquiries.',
    S33: 'Months since most recent inquiry was 13-18 months ago. Optimum value is no inquiries.',
    S34: 'Months since most recent inquiry was 19-24 months ago. Optimum value is no inquiries.',
    S40: 'Most recent auto inquiry was in current month. Optimum is no auto inquiries.',
    S41: 'Most recent auto inquiry was 1-6 months ago. Optimum is no auto inquiries.',
    S42: 'Most recent auto inquiry was 7-12 months ago. Optimum is no auto inquiries.',
    S43: 'Most recent auto inquiry was 13-18 months ago. Optimum is no auto inquiries.',
    S44: 'Most recent auto inquiry was 19-24 months ago. Optimum is no auto inquiries.',
    U00: 'Utilization of open bank revolving accounts updated in last 12 months cannot be calculated because no credit limit is reported. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U01: 'No open verified bank revolving accounts. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U02: 'Utilization of open bank revolving accounts is 0%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U03: 'Utilization of open bank revolving accounts is 1% to 9%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U04: 'Utilization of open bank revolving accounts is 10% to 19%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U05: 'Utilization of open bank revolving accounts is 20% to 29%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U06: 'Utilization of open bank revolving accounts is 30% to 39%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U07: 'Utilization of open bank revolving accounts is 40% to 49%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U08: 'Utilization of open bank revolving accounts is 50% to 59%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U09: 'Utilization of open bank revolving accounts is 60% to 69%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U0A: 'Utilization of open bank revolving accounts is 70% to 79%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U0B: 'Utilization of open bank revolving accounts is 80% to 89%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U0C: 'Utilization of open bank revolving accounts is 90% to 99%. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U0D: 'Utilization of open bank revolving accounts is 100% or more. Optimum value is utilization of bank revolving accounts > 0% and < 1%.',
    U10: 'Aggregate installment payment/minimum payment ratio over last 3 months cannot be calculated because there are no installment accounts or there are no payments reported. Optimum value is 100% or mor.',
    U11: 'Aggregate installment payment/minimum payment ratio over last 3 months is 0%. Optimum value is 100% or more.',
    U12: 'Aggregate installment payment/minimum payment ratio over last 3 months is 0.1%-13.9%. Optimum value is => 100%.',
    U13: 'Aggregate installment payment/minimum payment ratio over last 3 months is 14%-32.9%. Optimum value is => 100%.',
    U14: 'Aggregate installment payment/minimum payment ratio over last 3 months is 33%-60.9%. Optimum value is => 100%.',
    U15: 'Aggregate installment payment/minimum payment ratio over last 3 months is 61%-99.9%. Optimum value is => 100%.',
    U20: 'Slope of credit card utilization after payment over last 12 months is < -0.5. Optimum value is => 0 and < 0.1.',
    U21: 'Slope of credit card utilization after payment over last 12 months is => -0.5 and < 0. Optimum value is => 0 and < 0.1.',
    U22: 'Slope of credit card utilization after payment over last 12 months is => 0.1 and < 1.3. Optimum value is => 0 and < 0.1.',
    U23: 'Slope of credit card utilization after payment over last 12 months is => 1.3. Optimum value is => 0 and < 0.1.',
    U30: 'Number of credit card balance decreases over last 12 months cannot be calculated because there are no credit cards or balance is not reported. Optimum value is 12 or more.',
    U31: 'Number of credit card balance decreases over last 12 months is 0. Optimum value is 12 or more.',
    U32: 'Number of credit card balance decreases over last 12 months is 1. Optimum value is 12 or more.',
    U33: 'Number of credit card balance decreases over last 12 months is 2. Optimum value is 12 or more.',
    U34: 'Number of credit card balance decreases over last 12 months is 3. Optimum value is 12 or more.',
    U35: 'Number of credit card balance decreases over last 12 months is 4. Optimum value is 12 or more.',
    U36: 'Number of credit card balance decreases over last 12 months is 5. Optimum value is 12 or more.',
    U37: 'Number of credit card balance decreases over last 12 months is 6. Optimum value is 12 or more.',
    U38: 'Number of credit card balance decreases over last 12 months is 7. Optimum value is 12 or more.',
    U39: 'Number of credit card balance decreases over last 12 months is 8-11. Optimum value is 12 or more.',
    U40: 'Aggregate credit card payment/balance ratio over last 12 months cannot be calculated because there are no credit cards or because balance and/or payment is not reported. Optimum value is 100% or more.',
    U41: 'Aggregate credit card payment/balance ratio over last 12 months is 0%-14%. Optimum value is 100% or more.',
    U42: 'Aggregate credit card payment/balance ratio over last 12 months is 15%-29%. Optimum value is 100% or more.',
    U43: 'Aggregate credit card payment/balance ratio over last 12 months is 30%-44%. Optimum value is 100% or more.',
    U44: 'Aggregate credit card payment/balance ratio over last 12 months is 45%-59%. Optimum value is 100% or more.',
    U45: 'Aggregate credit card payment/balance ratio over last 12 months is 60%-74%. Optimum value is 100% or more.',
    U46: 'Aggregate credit card payment/balance ratio over last 12 months is 75%-89%. Optimum value is 100% or more.',
    U47: 'Aggregate credit card payment/balance ratio over last 12 months is 90%-99%. Optimum value is 100% or more.',
    U50: 'Utilization of credit cards cannot be calculated because no credit limit is reported. Optimum value is utilization equal to 2%.',
    U51: 'No accounts on file. Optimum value is credit card utilization equal to 2%.',
    U52: 'No open verified credit cards. Optimum value is credit card utilization equal to 2%.',
    U53: 'Utilization of credit cards is 0%. Optimum value is utilization equal to 2%.',
    U54: 'Utilization of credit cards is 1%. Optimum value is utilization equal to 2%.',
    U55: 'Utilization of credit cards is 3%-9%. Optimum value is utilization equal to 2%.',
    U56: 'Utilization of credit cards is 10%-19%. Optimum value is utilization equal to 2%.',
    U57: 'Utilization of credit cards is 20%-29%. Optimum value is utilization equal to 2%.',
    U58: 'Utilization of credit cards is 30%-39%. Optimum value is utilization equal to 2%.',
    U59: 'Utilization of credit cards is 40%-49%. Optimum value is utilization equal to 2%.',
    U5A: 'Utilization of credit cards is 50%-59%. Optimum value is utilization equal to 2%.',
    U5B: 'Utilization of credit cards is 60%-69%. Optimum value is utilization equal to 2%.',
    U5C: 'Utilization of credit cards is 70%-79%. Optimum value is utilization equal to 2%.',
    U5D: 'Utilization of credit cards is 80%-89%. Optimum value is utilization equal to 2%.',
    U5E: 'Utilization of credit cards is 90%-99%. Optimum value is utilization equal to 2%.',
    U5F: 'Utilization of credit cards is > 99%. Optimum value is utilization equal to 2%.'
};
