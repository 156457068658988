import gql from 'graphql-tag';

export const PolicyPreviewResponse = gql`
  fragment PolicyPreviewResponse on PolicyPreview {
    id
    coverageChange
    itemsChanged
    billingDescription
    newPremium
    newTotalPrice
    clarionDoorIds {
      requestIdBeforePreview
      requestIdAfterPreview
    }
  }
`;

export const Address = gql`
  fragment Address on AddressDetails {
    address
    address2
    unit
    country
    city
    state
    zip
  }
`;

export const People = gql`
  fragment People on PersonDetails {
    id
    age
    isPrimary
    isCoApplicant
    firstName
    middleName
    lastName
    suffix
    gender
    excludeDriver
    maritalStatus
    takenCourse
    highestEducation
    dateOfBirth
    driversLicenseNumber
    driversLicenseState
    ageFirstLicensed
    fullTimeStudent
    goodStudent
    schoolName
    addDate
    communityDriveParticipation
    insuranceScore {
      homeTotal
      autoTotal
      total
    }
    schoolLocation {
      ...Address
    }
    autoViolationDates
    waivedPIPWL
    pipEligible
    pipOptOut
    pipHasQHC
    assignedCar
  }
`;
