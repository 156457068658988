import React, { useCallback } from 'react';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { setState, limitedBranchWindhailCoverage, windHailDeductibleCanVaryByCounty } from '@ourbranch/lookups';

import { useCurrentState } from 'common/hooks/useCurrentState';
import Field from 'core/components/form/form.v2';
import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { NotificationCard } from 'core/components/notification-card';
import RemoveButton from 'core/components/remove-button';
import { useStore } from 'core/store';
import styles from '../styles';

const id = 'homeCoverage';

const getDisclaimers = () => ({
  AL: {
    firstPart: 'Branch does not cover for wind or hail damage in this zipcode. This coverage can be purchased through',
    link: 'https://aiua.org/',
    linkText: 'AIUA',
    thirdPart: 'as an additional purchase.'
  },
  GA: {
    firstPart:
      'Unfortunately, Branch does not cover damage caused by wind or hail at this address due to its proximity to the coastline. Coverage for this type of loss can be purchased through the',
    link: 'https://www.georgiaunderwriting.com/',
    linkText: 'GUA'
  }
});

const WindHailFlow = observer(
  ({
    classes,
    formik: {
      setFieldValue,
      setFieldTouched,
      values: { homeCoverage, home, ...formikValues }
    },
    fromPolicy
  }) => {
    const state = useCurrentState(formikValues);
    const {
      account: {
        policies: { policy: policyStore }
      }
    } = useStore();
    const externalPolicyId = policyStore.policy?.policyDetails?.homeCoverage?.externalPolicyId;
    const { interestedInSeparateWindHail } = homeCoverage;
    const disclaimer = getDisclaimers()[state];
    const showDisclaimer =
      disclaimer &&
      (!limitedBranchWindhailCoverage || !limitedBranchWindhailCoverage[state]) &&
      ((windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][home.county]) ||
        (state === 'AL' && !fromPolicy));
    setState(state);

    const onRemovePolicyId = useCallback(() => {
      setFieldValue(`${id}.externalPolicyId`, null);
      setFieldTouched(`${id}.externalPolicyId`);
    }, [setFieldValue, setFieldTouched]);

    return (
      <>
        {showDisclaimer && (
          <NotificationCard type="quaternary">
            <Grid container alignItems="center">
              {disclaimer.firstPart}{' '}
              <a rel="noreferrer noopener" target="_blank" href={disclaimer.link} className={classes.link}>
                {disclaimer.linkText}
              </a>{' '}
              {disclaimer.thirdPart}
            </Grid>
          </NotificationCard>
        )}
        {state === 'AL' && (
          <>
            <Field
              id={`${id}.interestedInSeparateWindHail`}
              name={`${id}.interestedInSeparateWindHail`}
              type="switch"
              label="Purchase Wind & Hail coverage separately"
              mode="dark"
              xs={12}
              fast={false}
            />
            {fromPolicy && interestedInSeparateWindHail && (
              <>
                {!externalPolicyId && (
                  <NotificationCard>
                    <Grid container alignItems="center" justify="space-between">
                      <div>
                        Pending purchase through<strong>&nbsp;AIUA&nbsp;</strong>
                      </div>
                      <Button className={classes.button}>
                        <a
                          className={classes.buttonLink}
                          rel="noreferrer noopener"
                          target="_blank"
                          href="https://aiua.org/"
                        >
                          Purchase off site
                        </a>
                      </Button>
                    </Grid>
                  </NotificationCard>
                )}
                {externalPolicyId ? (
                  <Grid container alignItems="center" className={classes.purchasedContainer}>
                    <Grid item xs={5}>
                      <Label type="coverageParagraph">
                        Purchased through partner <strong>&nbsp;AIUA&nbsp;</strong>
                      </Label>
                    </Grid>
                    <FormField
                      name={`${id}.externalPolicyId`}
                      type="value"
                      label="Policy ID"
                      xs={3}
                      mode="dark"
                      value={externalPolicyId}
                    />
                    <Grid item xs={4}>
                      <Button className={classes.button}>
                        <a
                          className={classes.buttonLink}
                          rel="noreferrer noopener"
                          target="_blank"
                          href="https://aiua.org/"
                        >
                          View policy
                        </a>
                      </Button>
                      <RemoveButton mode="big" onClick={onRemovePolicyId} />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container alignItems="center">
                    <Grid item xs={8}>
                      <Label type="coverageParagraph">
                        Once purchased, enter the Policy ID to save it to this policy.
                      </Label>
                    </Grid>
                    <FormField name={`${id}.externalPolicyId`} type="string" label="Policy ID" xs={4} mode="dark" />
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
);

WindHailFlow.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  fromPolicy: PropTypes.bool
};

WindHailFlow.defaultProps = {
  fromPolicy: false
};

export default flowRight(withStyles(styles), connect)(WindHailFlow);
