"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trueRiskPropertyReasons = void 0;
exports.trueRiskPropertyReasons = {
    DHA: 'Months since most recent delinquency is between 0 and 10. Optimum value is no delinquencies. Score will improve with time as delinquencies get older and there are no new delinquencies.',
    DHB: 'Months since most recent delinquency is between 11 and 20. Optimum value is no delinquencies. Score will improve with time as delinquencies get older and there are no new delinquencies.',
    DHC: 'Months since most recent delinquency is between 21 and 30. Optimum value is no delinquencies. Score will improve with time as delinquencies get older and there are no new delinquencies.',
    DHD: 'Months since most recent delinquency is between 31 and 40. Optimum value is no delinquencies. Score will improve with time as delinquencies get older and there are no new delinquencies.',
    DHE: 'Months since most recent delinquency is between 41 and 50. Optimum value is no delinquencies. Score will improve with time as delinquencies get older and there are no new delinquencies.',
    DHF: 'Months since most recent delinquency is between 51 and 60. Optimum value is no delinquencies. Score will improve with time as delinquencies get older and there are no new delinquencies.',
    DHG: 'Months since most recent delinquency is between 61 and 70. Optimum value is no delinquencies. Score will improve with time as delinquencies get older and there are no new delinquencies.',
    DHH: 'Months since most recent delinquency is between 71 and 80. Optimum value is no delinquencies. Score will improve with time as delinquencies get older and there are no new delinquencies.',
    DIA: 'Number of collections in the last seven years is between 1 and 2. Optimum value is no collections. Score will improve with fewer collections during the most recent seven years.',
    DIB: 'Number of collections in the last seven years is between 3 and 4. Optimum value is no collections. Score will improve with fewer collections during the most recent seven years.',
    DIC: 'Number of collections in the last seven years is between 5 and 6. Optimum value is no collections. Score will improve with fewer collections during the most recent seven years.',
    DID: 'Number of collections in the last seven years is between 7 and 8. Optimum value is no collections. Score will improve with fewer collections during the most recent seven years.',
    DIE: 'Number of collections in the last seven years is between 9 and 10. Optimum value is no collections. Score will improve with fewer collections during the most recent seven years.',
    DIF: 'Number of collections in the last seven years is 11 or more. Optimum value is no collections. Score will improve with fewer collections during the most recent seven years.',
    DJA: 'There are 1 or more accounts updated in the last 12 months, with a balance/credit limit ratio >75%, that are considered major derogatory accounts. Optimum value is no accounts updated in the last 12 months, with a balance/credit limit ratio >75% that are considered major derogatory accounts. Score will improve with fewer accounts updated in the last 12 months, with a balance/credit limit ratio >75% that are considered major derogatory accounts.',
    DKA: 'Number of adverse public records in the last up to 10 years is 1 or more. Optimum value is no adverse public records in the last up to 10 years. Score will improve with fewer adverse public records during the most recent 10 years.',
    DLA: 'Number of accounts 30 or more days past due in the last 6 months is 1 or more. Optimum value is less than 1. Score will improve with fewer accounts 30 or more days past due in the last 6 months.',
    DMA: 'Number of accounts ever 30 or more days past due, in the last seven years is 1 or more. Optimum value is no such past-due accounts. Score will improve with fewer accounts ever 30 or more days past due during the most recent seven years.',
    DNA: 'Number of 90 days past due or worse items ever is between 1 and 2. Optimum value is none. Score will improve with fewer number of 90 days past due or worse items.',
    DNB: 'Number of 90 days past due or worse items ever is between 3 and 4. Optimum value is none. Score will improve with fewer number of 90 days past due or worse items.',
    DNC: 'Number of 90 days past due or worse items ever is between 5 and 6. Optimum value is none. Score will improve with fewer number of 90 days past due or worse items.',
    DND: 'Number of 90 days past due or worse items ever is 7 or more. Optimum value is none. Score will improve with fewer number of 90 days past due or worse items.',
    DOA: 'Number of other public records in last 5 years is 1 or more. Optimum value is less than 1. Score will improve with fewer number of public records total history.',
    DPA: 'Number of adverse public records in the last 5 years is 1 or more. Optimum value is no adverse public records in the last 5 years. Score will improve with fewer adverse public records during the most recent 5 years.',
    GAA: 'Number of home equity accounts updated in the last 5 years is 1 or more. Optimum value is no home equity accounts updated in the last 5 years. Score will improve with fewer home equity accounts.',
    GBA: 'Number of auto trades verified in the last 5 years ever 30+ past due is 1. Optimum value is less than 1. Score will improve with fewer auto trades past due 30+.',
    GBB: 'Number of auto trades verified in the last 5 years ever 30+ past due is 2. Optimum value is less than 1. Score will improve with fewer auto trades past due 30+.',
    GBC: 'Number of auto trades verified in the last 5 years ever 30+ past due is 3 or more. Optimum value is less than 1. Score will improve with fewer auto trades past due 30+.',
    GCA: 'Number of credit card accounts opened in the last 6 months is one or more. Optimum value is no credit card accounts opened in the last 6 months. Score will improve with fewer credit card accounts opened in the last 6 months.',
    GDA: 'Number of mortgage accounts in the total credit history is between 1 and 4. Optimum value is no mortgage accounts in the total credit history. Score will improve with fewer mortgage accounts in the total credit history.',
    GDB: 'Number of mortgage accounts in the total credit history is between 5 and 8. Optimum value is no mortgage accounts in the total credit history. Score will improve with fewer mortgage accounts in the total credit history.',
    GDC: 'Number of mortgage accounts in the total credit history is between 9 and 12. Optimum value is no mortgage accounts in the total credit history. Score will improve with fewer mortgage accounts in the total credit history.',
    GDD: 'Number of mortgage accounts in the total credit history is between 13 and 15. Optimum value is no mortgage accounts in the total credit history. Score will improve with fewer mortgage accounts in the total credit history.',
    GDE: 'Number of mortgage accounts in the total credit history is 16 or more. Optimum value is no mortgage accounts in the total credit history. Score will improve with fewer mortgage accounts in the total credit history.',
    GDI: 'Total number of accounts is 65 or more. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GEA: 'Months since most recent credit card trade opened verified in last 5 years  is null. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GEB: 'Months since most recent credit card trade opened verified in last 5 years  is 0. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GEC: 'Months since most recent credit card trade opened verified in last 5 years  is between 1 and 29. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GED: 'Months since most recent credit card trade opened verified in last 5 years  is between 30 and 39. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GEE: 'Months since most recent credit card trade opened verified in last 5 years  is between 40 and 79. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GEF: 'Months since most recent credit card trade opened verified in last 5 years  is between 80 and 119. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GEG: 'Months since most recent credit card trade opened verified in last 5 years  is between 120 and 159. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GEH: 'Months since most recent credit card trade opened verified in last 5 years  is between 160 and 1999. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GEI: 'Months since most recent credit card trade opened verified in last 5 years  is between 200 and 239. Opitimum value is 240 or more. Score will improve as months since credit card opened increases.',
    GFA: 'There are no bank revolving accounts. Optimum value is 1. Score may improve with the opening of a bank revolving account.',
    GFB: 'Number of bank revolving accounts is zero. Optimum value is 1. Score may improve with the opening of a bank revolving account.',
    GFC: 'Number of bank revolving accounts is between 2 and 9. Optimum value is 1. Score will improve with fewer bank revolving accounts.',
    GFD: 'Number of bank revolving accounts is between 10 and 19. Optimum value is 1. Score will improve with fewer bank revolving accounts.',
    GFE: 'Number of bank revolving accounts is between 20 and 29. Optimum value is 1. Score will improve with fewer bank revolving accounts.',
    GFF: 'Number of bank revolving accounts is between 30 and 39. Optimum value is 1. Score will improve with fewer bank revolving accounts.',
    GFG: 'Number of bank revolving accounts is 40 or more. Optimum value is 1. Score will improve with fewer bank revolving accounts.',
    GGA: 'Number of credit card trades total history is between 3 and 29. Optimum value is 2 or less. Score will improve with less credit card trades.',
    GGB: 'Number of credit card trades total history is between 30 and 39. Optimum value is 2 or less. Score will improve with less credit card trades.',
    GGC: 'Number of credit card trades total history is between 40 and 49. Optimum value is 2 or less. Score will improve with less credit card trades.',
    GGD: 'Number of credit card trades total history is 50 or more. Optimum value is 2 or less. Score will improve with less credit card trades.',
    GHA: 'Number of trades opened in the last 6 months is between 1 and 4. Optimum value is 0. Score will improve with less trades opened in last 6 months.',
    GHB: 'Number of trades opened in the last 6 months is 5 or more. Optimum value is 0. Score will improve with less trades opened in last 6 months.',
    GIA: 'Number of trades total history is 0. Optimum value is 1. Score will improve with less trades and more than 0 trades.',
    GIB: 'Number of trades total history is between 2 and 19. Optimum value is 1. Score will improve with less trades and more than 0 trades.',
    GIC: 'Number of trades total history is between 20 and 29. Optimum value is 1. Score will improve with less trades and more than 0 trades.',
    GID: 'Number of trades total history is between 30 and 39. Optimum value is 1. Score will improve with less trades and more than 0 trades.',
    GIE: 'Number of trades total history is between 40 and 49. Optimum value is 1. Score will improve with less trades and more than 0 trades.',
    GIF: 'Number of trades total history is between 65 or more. Optimum value is 1. Score will improve with less trades and more than 0 trades.',
    GIG: 'Number of trades total history is between 50 and 64. Optimum value is 1. Score will improve with less trades and more than 0 trades.',
    GJA: 'Total number of accounts is between 0 and 2. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GJB: 'Total number of accounts is between 4 and 9. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GJC: 'Total number of accounts is between 10 and 19. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GJD: 'Total number of accounts is between 20 and 29. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GJE: 'Total number of accounts is between 30 and 39. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GJF: 'Total number of accounts is between 40 and 49. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GJG: 'Total number of accounts is between 50 and 59. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GJH: 'Total number of accounts is between 60 and 69. Optimum value is 3. Score will improve with fewer accounts, but more than 2 accounts.',
    GJI: 'Total number of accounts is > 64. Optimum value is 3.  ',
    SAA: 'There are no bank revolving accounts. Optimum value is 240 or more months since most recent bank revolving account was opened. Score may improve with the opening of a bank revolving account and will improve as the bank revolving account gets older.',
    SAB: 'There are no bank revolving accounts. Optimum value is 240 or more months since most recent bank revolving account was opened. Score may improve with the opening of a bank revolving account and will improve as the bank revolving account gets older.',
    SAC: 'Months since most recent bank revolving account opened is between 0 and 5. Optimum value is 240 or more months since most recent bank revolving account was opened. Score will improve as bank revolving accounts get older and no new bank revolving accounts are added.',
    SAD: 'Months since most recent bank revolving account opened is between 6 and 39. Optimum value is 240 or more months since most recent bank revolving account was opened. Score will improve as bank revolving accounts get older and no new bank revolving accounts are added.',
    SAE: 'Months since most recent bank revolving account opened is between 40 and 79. Optimum value is 240 or more months since most recent bank revolving account was opened. Score will improve as bank revolving accounts get older and no new bank revolving accounts are added.',
    SAF: 'Months since most recent bank revolving account opened is between 80 and 119. Optimum value is 240 or more months since most recent bank revolving account was opened. Score will improve as bank revolving accounts get older and no new bank revolving accounts are added.',
    SAG: 'Months since most recent bank revolving account opened is between 120 and 159. Optimum value is 240 or more months since most recent bank revolving account was opened. Score will improve as bank revolving accounts get older.',
    SAH: 'Months since most recent bank revolving account opened is between 160 and 199. Optimum value is 240 or more months since most recent bank revolving account was opened. Score will improve as bank revolving accounts get older and no new bank revolving accounts are added.',
    SAI: 'Months since most recent bank revolving account opened is between 200 and 239. Optimum value is 240 or more months since most recent bank revolving account was opened. Score will improve as bank revolving accounts get older and no new bank revolving accounts are added.',
    SAJ: 'Months since most recent bank revolving trade opened is between 1 and 5. Optimum value is 240 or more. Score will improve as months since most recent bank revolving trade opened increases.',
    SBA: 'Months since most recent inquiry is between 0 and 6. Optimum value is none. Score will improve with less inquiries.',
    SBB: 'Months since most recent inquiry is between 7 and 12. Optimum value is none. Score will improve with less inquiries.',
    SBC: 'Months since most recent inquiry is between 13 and 18. Optimum value is none. Score will improve with less inquiries.',
    SBD: 'Months since most recent inquiry is between 19 and 23. Optimum value is none. Score will improve with less inquiries.',
    SBE: 'Months since most recent inquiry is 24 or more. Optimum value is none. Score will improve with less inquiries.',
    SCA: 'Number of consumer-initiated inquiries during the past 24 months is between 1 and 5. Optimum value is no inquiries during the past 24 months. Score will improve as inquiries get older and there are no new inquiries.',
    SCB: 'Number of consumer-initiated inquiries during the past 24 months is between 6 and 11. Optimum value is no inquiries during the past 24 months. Score will improve as inquiries get older and there are no new inquiries.',
    SCC: 'Number of consumer-initiated inquiries during the past 24 months is between 12 and 17. Optimum value is no inquiries during the past 24 months. Score will improve as inquiries get older and there are no new inquiries.',
    SCD: 'Number of consumer initiated inquiries during the past 24 months is 18 or more. Optimum value is no inquiries during the past 24 months. Score will improve as inquiries get older and there are no new inquiries.',
    UAA: 'Percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is between 2% and 10%. Optimum value is less than 2%. Score will improve when percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is lower.',
    UAB: 'Percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is between 11% and 20%. Optimum value is less than 2%. Score will improve when percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is lower.',
    UAC: 'Percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is between 21% and 30%. Optimum value is less than 2%. Score will improve when percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is lower.',
    UAD: 'Percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is between 31% and 40%. Optimum value is less than 2%. Score will improve when percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is lower.',
    UAE: 'Percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is between 41% and 50%. Optimum value is less than 2%. Score will improve when percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is lower.',
    UAF: 'Percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is between 51% and 60%. Optimum value is less than 2%. Score will improve when percentage of open credit card accounts with a balance/credit limit ratio >75% in last 12 months is lower.',
    UAG: 'Percentage of open credit card accounts with a balance/credit limit ratio >75% updated in last 12 months is 61% or more. Optimum value is less than 2%. Score will improve when percentage of open credit card accounts with a balance/credit limit ratio >75% in last 12 months is lower.',
    UBA: 'Utilization of open bank revolving trades verified in the last 12 months is null. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBB: 'Utilization of open bank revolving trades verified in the last 12 months is 0 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBC: 'Utilization of open bank revolving trades verified in the last 12 months is 1 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBD: 'Utilization of open bank revolving trades verified in the last 12 months is between 3 and 9 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBE: 'Utilization of open bank revolving trades verified in the last 12 months is between 10 and 19 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBF: 'Utilization of open bank revolving trades verified in the last 12 months is between 20 and 29 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBG: 'Utilization of open bank revolving trades verified in the last 12 months is between 30 and 39 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBH: 'Utilization of open bank revolving trades verified in the last 12 months is between 40 and 49 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBI: 'Utilization of open bank revolving trades verified in the last 12 months is between 50 and 59 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBJ: 'Utilization of open bank revolving trades verified in the last 12 months is between 60 and 69 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBK: 'Utilization of open bank revolving trades verified in the last 12 months is between 70 and 79 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBL: 'Utilization of open bank revolving trades verified in the last 12 months is between 80 and 89 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBM: 'Utilization of open bank revolving trades verified in the last 12 months is between 90 and 99 percent. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UBN: 'Utilization of open bank revolving trades verified in the last 12 months is 100 percent or more. Optimum value is 2 percent. Score will improve when utilization of open bank revolving trades verified in the last 12 months is 2%.',
    UCA: 'Utilization of open credit card trades verified in last 12 months is 0. Optimum value is 1. Score will improve with less utilization.',
    UCB: 'Utilization of open credit card trades verified in last 12 months is null. Optimum value is 1. Score will improve with less utilization.',
    UCC: 'Utilization of open credit card trades verified in last 12 months is between 2 and 19. Optimum value is 1. Score will improve with less utilization.',
    UCD: 'Utilization of open credit card trades verified in last 12 months is between 20 and 29. Optimum value is 1. Score will improve with less utilization.',
    UCE: 'Utilization of open credit card trades verified in last 12 months is between 30 and 39. Optimum value is 1. Score will improve with less utilization.',
    UCF: 'Utilization of open credit card trades verified in last 12 months is between 40 and 49. Optimum value is 1. Score will improve with less utilization.',
    UCG: 'Utilization of open credit card trades verified in last 12 months is between 50 and 59. Optimum value is 1. Score will improve with less utilization.',
    UCH: 'Utilization of open credit card trades verified in last 12 months is between 60 and 69. Optimum value is 1. Score will improve with less utilization.',
    UCI: 'Utilization of open credit card trades verified in last 12 months is between 70 and 79. Optimum value is 1. Score will improve with less utilization.',
    UCJ: 'Utilization of open credit card trades verified in last 12 months is between 80 and 89. Optimum value is 1. Score will improve with less utilization.',
    UCK: 'Utilization of open credit card trades verified in last 12 months is between 90 and 99. Optimum value is 1. Score will improve with less utilization.',
    UCL: 'Utilization of open credit card trades verified in last 12 months is 100 or more. Optimum value is 1. Score will improve with less utilization.',
    UDA: 'Number of bankcard trades verified in the 2 months with a balance > 0 is less than 1. Optimum value is 1 or more. Score will improve with greater number of bankcard trades verified in the 2 months with a balance > 0. ',
    UEA: 'There are no bank revolving accounts updated in the last 12 months. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score may improve with the opening of a bank revolving account.  Once such open account exists, score will improve with less utilization of the bank revolving account.',
    UEB: 'Utilization of open bank revolving accounts updated in the last 12 months is between 3% and 9%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEC: 'Utilization of open bank revolving accounts updated in the last 12 months is between 10% and 19%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UED: 'Utilization of open bank revolving accounts updated in the last 12 months is between 20% and 29%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEE: 'Utilization of open bank revolving accounts updated in the last 12 months is between 30% and 39%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEF: 'Utilization of open bank revolving accounts updated in the last 12 months is between 40% and 49%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEG: 'Utilization of open bank revolving accounts updated in the last 12 months is between 50% and 59% percent. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEH: 'Utilization of open bank revolving accounts updated in the last 12 months is between 60% and 69%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEI: 'Utilization of open bank revolving accounts updated in the last 12 months is between 70% and 79%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEJ: 'Utilization of open bank revolving accounts updated in the last 12 months is between 80% and 89%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEK: 'Utilization of open bank revolving accounts updated in the last 12 months is between 90% and 99%. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.',
    UEL: 'Utilization of open bank revolving accounts updated in the last 12 months is 100% or more. Optimum value is utilization of less than 3% of open bank revolving accounts, updated in the last 12 months. Score will improve with less utilization of bank revolving accounts.'
};
