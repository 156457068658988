"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.slackChannels = exports.fullStateNamesToAbbreviations = exports.executionSummaryMapping = exports.stripeCustomerUrl = exports.surplusContributionMultiplier = exports.yorkClaimMapping = exports.raterBlacklist = exports.notifyMediaAlphaEvents = exports.creditThresholdStates = exports.drivingViolationsByState = exports.safeDrivingViolationsByState = exports.noRateCodesByState = exports.clarionDoorURL = exports.startedLoggingReceivedCVData = exports.activeStatus = exports.medpayFormRegenerateStates = exports.nonBixImageryFeeStates = exports.nonBixPolicyFeeStates = exports.cancelFeeStates = exports.cancelReasonsForFee = exports.referral = exports.escrowPaymentMethod = exports.paymentTypeText = exports.docIdToFriendlyName = exports.separateUmUimOnFormsStates = exports.umOnlyOnLabelStates = exports.separateUMandUIMOnFormsStates = exports.listExcludedDriversOnIDCardsStates = exports.umpdPerPolicyStates = exports.umUimIncludedInPerCarTotal = exports.alternateUMUIMStates = exports.alternateFRStates = exports.aleTimeLinePerState = exports.premiumOrPriceStates = exports.documentTypes = exports.trueRiskPropertyReasons = exports.creditVisionAutoReasons = exports.carsWithPassiveRestraint = exports.templates = void 0;
var templates_1 = require("./templates");
Object.defineProperty(exports, "templates", { enumerable: true, get: function () { return templates_1.templates; } });
var carsWithPassiveRestraint_1 = require("./carsWithPassiveRestraint");
Object.defineProperty(exports, "carsWithPassiveRestraint", { enumerable: true, get: function () { return carsWithPassiveRestraint_1.carsWithPassiveRestraint; } });
var creditVisionAutoReasons_1 = require("./creditVisionAutoReasons");
Object.defineProperty(exports, "creditVisionAutoReasons", { enumerable: true, get: function () { return creditVisionAutoReasons_1.creditVisionAutoReasons; } });
var trueRiskPropertyReasons_1 = require("./trueRiskPropertyReasons");
Object.defineProperty(exports, "trueRiskPropertyReasons", { enumerable: true, get: function () { return trueRiskPropertyReasons_1.trueRiskPropertyReasons; } });
exports.documentTypes = {
    APPLICATION: 0,
    POLICY_JACKET: 1,
    ID_CARDS: 2,
    DECLARATION: 3,
    FCRA_NOTICE: 4,
    CANCELLATION: 5,
    POLICY_MOD_CONFIRMATION: 6,
    RENEWAL: 7,
    CONVERSION: 8,
    UM_NOTICE: 9,
    MEDPAY: 10,
    PIP_NOTICE: 11,
    BIX_CONVERSION_NON_RENEWAL: 12,
    NDEL_NOTICE: 13
};
exports.premiumOrPriceStates = {
    AL: 'Price',
    AZ: 'Price',
    CO: 'Price',
    IL: 'Price',
    IN: 'Price',
    KS: 'Price',
    KY: 'Price',
    MD: 'Price',
    MI: 'Price',
    MO: 'Premium',
    NE: 'Price',
    OH: 'Price',
    OK: 'Price',
    PA: 'Price',
    TX: 'Price',
    UT: 'Price',
    VA: 'Price',
    WI: 'Price',
    WV: 'Price'
};
exports.aleTimeLinePerState = {
    CO: 'Up to 24 months not to exceed'
};
exports.alternateFRStates = {
    PA: true
};
/** Now getting from the main rater */
exports.alternateUMUIMStates = {
    UT: false
};
exports.umUimIncludedInPerCarTotal = {
    TX: true
};
exports.umpdPerPolicyStates = {
    TX: true
};
exports.listExcludedDriversOnIDCardsStates = {
    MI: true,
    NE: true,
    OK: true,
    PA: true
};
exports.separateUMandUIMOnFormsStates = {
    UT: true
};
exports.umOnlyOnLabelStates = {
    OK: true,
    UT: true
};
/** Value not in use yet as of 9/1/21 */
exports.separateUmUimOnFormsStates = {
    PA: true
};
/** Friendly names for policy documents */
exports.docIdToFriendlyName = {
    A_AMND: 'Auto Policy Amendatory Endorsement',
    A_APPL: 'Auto Application',
    A_BIOF: 'Bodily Injury Option and Premiums',
    A_BISF: 'Choice of Bodily Injury Liability Coverage Limits',
    A_CNPY: 'Cancellation Notice',
    A_CNREL: 'Cancellation Notice',
    A_CNRET: 'Cancellation Notice',
    A_CNREV: 'Cancellation Notice',
    A_NRBIX: 'Insurance Cancellation Notice',
    A_CNRS: 'Underwriting Cancellation Notice',
    A_CODW: 'Collision Deductible Waiver',
    A_DECK: 'Auto Declarations',
    A_DECLL: 'Declination Notice',
    A_DECLT2: 'Declination Notice',
    A_DECLT: 'Declination Notice',
    A_DECLV: 'Declination Notice',
    A_DITR: 'Declaration of Intent to Reside',
    A_ICSE: 'Insurance Consultation Services Exemption',
    A_IDCD: 'Auto ID Cards',
    A_LOIW: 'Loss of Income Waiver',
    A_MEDP: 'MedPay Selection/Rejection Form',
    A_MPSF: 'Selection of Personal Injury Protection',
    A_NDEL: 'Named Driver Exclusion Election Form',
    A_NDEX: 'Named Driver Exclusion',
    A_NNOP: 'Named Non-Owner Coverage Endorsement',
    A_NOBE: 'Notice of Benefits',
    A_NOTO: 'Notice of Tort Options',
    A_NPRCT: 'Rescission Notice',
    A_NULL: 'Null Contract',
    A_PIPR: 'Pet Injury Endorsement',
    A_PIPS: 'Notice of Waiver of Personal Injury Protection',
    A_PJAK: 'Auto Policy',
    A_PLCY: 'Auto Policy',
    A_PSDS: 'Surcharge Disclosure',
    A_RENT: 'Renters Endorsement',
    A_RIDE: 'Rideshare Endorsement',
    A_SFSW: 'Auto Stress-Free Switch Letter',
    A_SMCQ: 'State Minimum Coverage Quote',
    A_TOSF: 'Tort Selection Rejection Form',
    A_UIMS: 'UIM Selection Rejection Form',
    A_UMSF: 'UM or UM/UIM Selection Rejection Form',
    A_WGPT: 'Window Glass Coverage Endorsement',
    A_WLBW: 'Work Loss Benefits Waiver',
    B_SASP: 'Subscriber Agreement Signature Page',
    B_AUTH: 'Recurring Payment Authorization',
    BRANCH_AUTO_ID_CARDS: 'Auto ID Cards',
    H_AAVK: 'Adverse Action Decision Notice',
    H_AMND: 'Home Policy Amendatory Endorsement',
    H_ANME: 'Animal Liability Exclusion',
    H_ANML: 'Animal Liability Limitation Endorsement',
    H_APPL: 'Homeowners Application',
    H_BIND: 'Home Insurance Proof of Insurance',
    H_CNPY: 'Cancellation Notice',
    H_CNRN: 'Insurance Cancellation Notice',
    H_CNRS: 'Underwriting Cancellation Notice',
    H_NRBIX: 'Insurance Cancellation Notice',
    H_DECK: 'Homeowners Declarations',
    H_DPUA: 'Homeowners Personal Umbrella Liability Declarations Addendum',
    H_EUSF: 'Excess UM/UIM Motorists Selection/Rejection Form',
    H_FCRA: 'Adverse Action Decision Notice',
    H_IB10: 'Increased Building Structure Protection 10% Endorsement',
    H_MINA: 'Mine Subsidence Insurance Underwriting Association Application',
    H_MNSF: 'Mine Subsidence Selection Rejection Form',
    H_MINE: 'Mine Subsidence Endorsement',
    H_NRREV: 'Non-renewal notice',
    H_NULL: 'Null Contract',
    H_PJAK: 'Homeowners Policy',
    H_PLCY: 'Homeowners Policy',
    H_PUMB: 'Personal Umbrella Liability Endorsement',
    H_RENI: 'Fair Rental Income Endorsement',
    H_ROFE: 'Roof Surfaces Extended Coverage',
    H_ROFM: 'Metal Roof Surfaces Cosmetic Damage Exclusion',
    H_ROOF: 'Roof Surfaces Endorsement',
    H_SHAR: 'Home Data Sharing Authorization',
    H_SFSW: 'Home Stress-Free Switch Letter',
    H_SPPE: 'Scheduled Personal Property ',
    H_WNHD: 'Windstorm and Hail Deductible Endorsement',
    H_WHXX: 'Wind/Hail Exclusion Form',
    H_WTBU: 'Water Backup Endorsement'
};
/** Descriptive text for whether you're paying up front or monthly */
exports.paymentTypeText = {
    I: 'monthly',
    F: 'up front',
    E: 'billed to mortgage lender'
};
exports.escrowPaymentMethod = 'W';
exports.referral = {
    payoutMethods: {
        manualPayoutId: 'f271c5d0-4c3c-4274-8276-1184b0238111',
        amazonGiftCardPayoutId: 'fd8e8811-61d5-4b9c-9324-7bf90abc0566'
    },
    programs: {
        inventoryUploadProgramId: '44511320-d510-4ef7-897c-b8ef7b08713a',
        referAndEarnProgramId: 'd10f7c85-5aa1-4ef4-a0a9-5ea4f753bba5'
    },
    payoutAmounts: {
        optionalInventorySubmissionPayoutUSD: 10
    }
};
/** At present, the reasons that don't generate a fee: CNCN, CNMV, CNUW, CNDP, CNBX, CNDE */
exports.cancelReasonsForFee = {
    CNDC: 1,
    CNPR: 1,
    CNDR: 1,
    CNDB: 1,
    CNMS: 1,
    CNPY: 1,
    CNID: 1,
    CNMV: 1,
    CNNO: 1,
    CNSO: 1,
    NA: 1
};
exports.cancelFeeStates = {
    AL: true,
    AZ: true,
    CO: true,
    IL: false,
    IN: true,
    KS: true,
    KY: true,
    MD: true,
    MI: false,
    MO: true,
    NE: true,
    OH: true,
    OK: true,
    PA: true,
    TX: true,
    UT: true,
    VA: true,
    WI: true,
    WV: false
};
exports.nonBixPolicyFeeStates = {
    AZ: true,
    MO: true,
    IL: true,
    OH: true,
    TX: true
};
exports.nonBixImageryFeeStates = {
    AZ: true,
    MO: true,
    IL: true,
    OH: true,
    TX: true
};
exports.medpayFormRegenerateStates = {
    CO: true
};
exports.activeStatus = 'ActiveStatus';
exports.startedLoggingReceivedCVData = '2019-10-01';
/** Append with a clarion door id */
exports.clarionDoorURL = 'https://gobranch.insuredapp.com/rating/prod-requests/';
exports.noRateCodesByState = {
    AZ: {
        startDateAsInt: 20210201,
        NAF: true,
        CMP: true,
        PUA: true,
        PUN: true,
        CMU: true
    },
    KS: {
        startDateAsInt: 20180101,
        NAF: true
    },
    MO: {
        startDateAsInt: 20180101,
        NAF: true,
        CMP: true
    },
    TX: {
        startDateAsInt: 20180101,
        PUA: true,
        PUN: true,
        CMP: true,
        CMU: true
    },
    UT: {
        stateDateAsInt: 20210101,
        NAF: true,
        CMP: true
    }
};
exports.safeDrivingViolationsByState = {
    AZ: {
        startDateAsInt: 20180101,
        violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI']
    },
    MO: { startDateAsInt: 20180101, violations: ['AAF', 'MAJ', 'MIN', 'SPD', 'DWI'] }
};
exports.drivingViolationsByState = {
    TX: ['DWI', 'MAJ']
};
exports.creditThresholdStates = {
    AL: { A: 283, H: 231 },
    AZ: { A: 385, H: 415 },
    CO: { A: 283, H: 218 },
    IL: { A: 283, H: 231 },
    IN: { A: 283, H: 231 },
    KS: { A: 825, H: 776 },
    KY: { A: 283, H: 231 },
    MD: { A: 283, H: 999 },
    MI: { A: 283, H: 231 },
    MO: { A: 385, H: 415 },
    NE: { A: 283, H: 231 },
    OH: { A: 283, H: 231 },
    OK: { A: 283, H: 231 },
    PA: { A: 283, H: 231 },
    TX: { A: 510, H: 472 },
    UT: { A: 283, H: 231 },
    VA: { A: 283, H: 218 },
    WI: { A: 283, H: 231 },
    WV: { A: 283, H: 231 }
};
exports.notifyMediaAlphaEvents = {
    /** Succesfully rated for a policy */
    REJECT_NO_CODE: 1,
    /** Succesfully bound a policy */
    BIND: 2,
    /** Rejected for a policy */
    REJECT_WITH_CODE: 3
};
exports.raterBlacklist = [
    {
        fname: 'kelly',
        lname: 'carver',
        address: '602 harris st',
        city: 'kent',
        state: 'OH'
    },
    {
        fname: 'chris',
        lname: 'bezucha',
        address: '207 s mobberly ave',
        city: 'longview',
        state: 'TX'
    }
];
exports.yorkClaimMapping = {
    'PROPERTY DAMAGE': 'E',
    'BODILY INJURY': 'E',
    /** O means we don't count it, but leaving here so we know we mapped it */
    MEDPAY: 'O',
    OTHER: 'F',
    'FIRE OR LIGHTNING': 'D',
    'WIND AND HAIL': 'F',
    'EXPLOSION OR IMPLOSION': 'D',
    'RIOT OF CIVIL COMMOTION': 'F',
    'DAMAGE BY VEHICLES': 'F',
    SMOKE: 'D',
    VANDALISM: 'E',
    'GLASS BREAKAGE': 'F',
    'CRIME (BURGLARY, THEFT)': 'E',
    DERAILMENT: 'F',
    'WATER DAMAGE': 'G',
    'FALLING OBJECTS': 'F',
    'WEIGHT OF ICE/SNOW/SLEET': 'G',
    COLLAPSE: 'F',
    'ELECTRIC CURRENTS': 'F',
    'SONIC BOOM': 'F',
    'EARTHQUAKE, VOLCANO': 'O',
    'ENVIRONMENTAL CONTAMINATE': 'F',
    'MUDSLIDE / EARTH MOVEMENT': 'O',
    'MECHANICAL BREAKDOWN': 'F',
    'LOSS ASSESSMENT': 'F',
    'OFF-PREMISES POWER INTERRUPTION': 'F'
};
exports.surplusContributionMultiplier = 0.02;
/** Append with a stripe customer id */
exports.stripeCustomerUrl = 'https://dashboard.stripe.com/customers/';
exports.executionSummaryMapping = {
    rejectionType: {
        HOME: 'home',
        AUTO: 'auto',
        BOTH: 'both',
        NONE: 'none'
    }
};
exports.fullStateNamesToAbbreviations = {
    ALABAMA: 'AL',
    ALASKA: 'AK',
    ARIZONA: 'AZ',
    ARKANSAS: 'AR',
    CALIFORNIA: 'CA',
    COLORADO: 'CO',
    CONNECTICUT: 'CT',
    DELAWARE: 'DE',
    'DISTRICT OF COLUMBIA': 'DC',
    FLORIDA: 'FL',
    GEORGIA: 'GA',
    HAWAII: 'HI',
    IDAHO: 'ID',
    ILLINOIS: 'IL',
    INDIANA: 'IN',
    IOWA: 'IA',
    KANSAS: 'KS',
    KENTUCKY: 'KY',
    LOUISIANA: 'LA',
    MAINE: 'ME',
    MARYLAND: 'MD',
    MASSACHUSETTS: 'MA',
    MICHIGAN: 'MI',
    MINNESOTA: 'MN',
    MISSISSIPPI: 'MS',
    MISSOURI: 'MO',
    MONTANA: 'MT',
    NEBRASKA: 'NE',
    NEVADA: 'NV',
    'NEW HAMPSHIRE': 'NH',
    'NEW JERSEY': 'NJ',
    'NEW MEXICO': 'NM',
    'NEW YORK': 'NY',
    'NORTH CAROLINA': 'NC',
    'NORTH DAKOTA': 'ND',
    OHIO: 'OH',
    OKLAHOMA: 'OK',
    OREGON: 'OR',
    PENNSYLVANIA: 'PA',
    'RHODE ISLAND': 'RI',
    'SOUTH CAROLINA': 'SC',
    'SOUTH DAKOTA': 'SD',
    TENNESSEE: 'TN',
    TEXAS: 'TX',
    UTAH: 'UT',
    VERMONT: 'VT',
    VIRGINIA: 'VA',
    WASHINGTON: 'WA',
    'WEST VIRGINIA': 'WV',
    WISCONSIN: 'WI',
    WYOMING: 'WY'
};
exports.slackChannels = {
    PARTNER_ERRORS: 'partner-errors',
    PARTNER_AGENCIES: 'partner-agencies',
    LETS_TALK_ERRORS: '4001-errors',
    DEV_TESTING: 'dev-testing',
    PARTNER_AGENCY_ERRORS: 'partner-agency-errors'
};
